import type { ComponentType } from "react"
import { useEffect } from "react"

function getUTMsFromURL() {
    const urlParams = new URLSearchParams(window.location.search)
    const utms = {
        utm_source: urlParams.get("utm_source") || "",
        utm_medium: urlParams.get("utm_medium") || "",
        utm_campaign: urlParams.get("utm_campaign") || "",
        utm_term: urlParams.get("utm_term") || "",
        utm_content: urlParams.get("utm_content") || "",
    }
    return utms
}

export function setUtmFieldsValue(Component): ComponentType {
    return (props) => {
        const utms = getUTMsFromURL()
        useEffect(() => {
            if (utms) {
                const inputElement = document.querySelector(
                    `input[name="${props.inputName}"]`
                )
                if (inputElement) {
                    ;(inputElement as HTMLInputElement).value =
                        utms[props.inputName]
                }
            }
        }, [utms])

        return <Component {...props} />
    }
}
